import React from "react";

import { AnimatePresence, motion } from "framer-motion";

import { Box, ButtonProps, Button as ChakraButton } from "@chakra-ui/react";

import { DotLoader } from "@components/DotLoader";
import { Text } from "@components/Text";

interface PropTypes extends ButtonProps {
    children: React.ReactNode;
}

const Button = ({
    children,
    isLoading,
    loadingText,
    isDisabled,
    size,
    ...buttonProps
}: PropTypes) => {
    const chakraButtonProps = {
        ...buttonProps,
        size,
        isDisabled: isLoading || isDisabled,
        opacity: isDisabled ? "0.4" : "1",
        height: size === "sm" ? 10 : 12,
        ...(isLoading ? { "data-loading": true } : {}) // Add "data-loading" prop if isLoading is true
    };

    return (
        <ChakraButton {...chakraButtonProps}>
            {!isLoading && children}
            {isLoading && loadingText && (
                <AnimatePresence>
                    <motion.div
                        animate={{
                            opacity: [1, 0.6, 1]
                        }}
                        transition={{
                            repeat: Infinity,
                            duration: 2,
                            ease: "easeInOut"
                        }}
                    >
                        <Text color="white" type="subHeading">
                            {loadingText}
                        </Text>
                    </motion.div>
                </AnimatePresence>
            )}
            {isLoading && loadingText ? (
                <Box right={0} position="absolute" mr={14}>
                    <DotLoader size={size as "sm" | "md" | "lg"} />
                </Box>
            ) : isLoading ? (
                <DotLoader size={size as "sm" | "md" | "lg"} />
            ) : null}
        </ChakraButton>
    );
};

export default Button;
